<template>
  <div>
    <!--  :submit-flag="submitFlag" -->
    <info-content
      :submit-flag="true"
      brgb="244,245,252"
      use-bstyle
      @submit="showDialog"
      @headerBack="headerBack"
      :headerImg="headerImg"
      :sub-text="$t('baseInfo.nextStep')"
    >
      <div class="data-form">
        <van-form ref="dataForm" :show-error-message="false" validate-first validate-trigger="onSubmit">
          <div class="step-line">
            <span>{{ $t('baseInfo.title') }}</span>
            <span>{{ $t('baseInfo.cashWithdraw') }}</span>
            <span>{{ $t('baseInfo.identityInformation') }}</span>
          </div>
          <div v-if="display_tips" class="add-explain">{{ $t('baseInfo.shenhetongguohou') }}</div>
          <van-swipe class="swipe-box" :initial-swipe="initialSwipe" @change="onChange">
            <van-swipe-item v-for="(item,index) in swipeList" :key="index">
              <electronic-wallet v-if="item.refid === '0'" ref="withdrawal0" :wallet-list="item.list" @setSubmitFlag="setSubmitFlag" />
              <!-- <money-shop v-if="item.refid === '1'" ref="withdrawal1" :remittance-list="item.list" @setSubmitFlag="setSubmitFlag" /> -->
              <bank-catch v-if="item.refid === '2'" ref="withdrawal2" :channel-id="item.channel_id" @setSubmitFlag="setSubmitFlag" />
            </van-swipe-item>
            <!-- <van-swipe-item>
              <money-shop ref="withdrawal1" @setSubmitFlag="setSubmitFlag" />
            </van-swipe-item>
            <van-swipe-item>
              <bank-catch ref="withdrawal2" @setSubmitFlag="setSubmitFlag" />
            </van-swipe-item> -->
          </van-swipe>
          <!-- <div class="nabasa" v-if="display_agreement">
            <img @click="isLoan=!isLoan" v-if="isLoan" src="@/assets/images/baseInfo/checked-icon.png" alt="">
            <img @click="isLoan=!isLoan" v-if="!isLoan" src="@/assets/images/baseInfo/no-checked-icon.png" alt="">
            <span>{{ $t('loan.woyiyuedutongyi') }}</span>
            <span @click="gotoLoan">{{ $t('loan.jiekuanxieyi') }}</span>
          </div> -->
        </van-form>
      </div>
    </info-content>
    <base-dialog
      v-if="dialogVisible"
      ref="baseDialog"
      show-cancel-button
      :confirm-button-text="$t('public.confirmBtn')"
      :cancel-button-text="$t('public.cancleBtn')"
      @confirm="confirm"
    >
      <template slot="node-slot">
        <div class="confirm-title">{{ confirmTitle }}</div>
        <div v-if="subRefs!=='withdrawal2'" class="only-number confirm-cont">{{ cardAccount }}</div>
        <div v-else class="confirm-bank">
          <div class="item">{{ $t('baseInfo.bankName') }}</div>
          <div class="confirm-cont">{{ cardName }}</div>
          <div class="item">{{ $t('baseInfo.bankNumber') }}</div>
          <div class="confirm-cont">{{ cardAccount }}</div>
        </div>
      </template>
    </base-dialog>

    <!-- <van-popup v-model="loanShow" round  >
      <div class="popup-class">
        <h3 class="title">{{ $t('settings.loanAgreement') }}</h3>
        <div class="popup-content">
          <loan ></loan>
        </div>
        <div class="footer-btn" @click="isLoan=true;loanShow=false">{{ $t('loan.myLookLoan') }}</div>
      </div>
    </van-popup> -->
  </div>
</template>

<script>

import perfectInformation from '../perfectInformation'
import { onVisibilityChange } from '@/utils'

import InfoContent from '../components/infoContent.vue'
import ElectronicWallet from './components/ElectronicWallet'
import BankCatch from './components/BankCatch'
import MoneyShop from './components/MoneyShop'
import loan from '../../agreementAbout/loanAgreement/index.vue'
import { Swipe, SwipeItem } from 'vant'
import { enchashmentWaySubmit, getBankData ,bankPoint} from '@/api/axios'
import { withdrawalSubmit } from '@/utils/mappingTable.ts'
export default {
  name: 'Withdrawal',
  components: {loan, ElectronicWallet, BankCatch, MoneyShop, InfoContent, 'van-swipe': Swipe, 'van-swipe-item': SwipeItem },
  mixins: [perfectInformation],
  data() {
    return {
      loanShow:false,//是否点击借款协议弹窗
      headerImg:require('@/assets/images/baseInfo/catch-type-icon.png'),
      dialogVisible: true,
      submitFlag: false,
      confirmTitle: this.$t('baseInfo.withdrawalTips')[0],
      initialSwipe: '0',
      subRefs: 'withdrawal0',
      cardName: '',
      cardAccount: '',
      dataForm: {},
      swipeList: [],
      bankPoint:{
        account_duration:0,//	账号输入时长
        account_updatecount:0,//	账号修改次数
        account_num:0,//	账号点击次数
        last_name_duration:0,//	last_name输入时长
        last_name_updatecount:0,//	last_name修改次数
        last_name_num:0,//	last_name点击次数
        first_name_duration:0,//	first_name输入时长
        first_name_updatecount:0,//	first_name修改次数
        first_name_num:0,//	first_name点击次数
        middle_name_duration:0,//	middle_name输入时长
        middle_name_updatecount:0,//	middle_name修改次数
        middle_name_num:0,//	middle_name点击次数
        way_num:0,//	提现方式选择次数
        confirm_num:0,//	确认账号点击次数
        submit_num:0,//	提交点击次数
        bank_num:0, // 银行卡 选择次数
        enter_card_duration:0, // 输入卡号 修改时长 
        enter_card_num:1, // 输入卡号 点击次数
        enter_card_updatecount:0, // 输入卡号 修改次数
        confirm_enter_card_num:0, // 确认输入卡号 点击次数
        select_payment_num:0, // 电子钱包选择次数
        withdraw_phone_duration:0, // 取款手机号 修改时长
        withdraw_phone_updatecount:0, // 取款手机号 修改次数
        withdraw_phone_num:0, // 取款手机号 点击次数
        remittance_num:0 // 提现方式：汇款店 选择次数
      },
      display_agreement:false,//是否展示借款协议 true是 false否
      display_tips:false,//是否展示提示文案 true是 false否
      isLoan:false,
    }
  },
  computed: {
    bankList: {
      set(val) {
        this.$store.dispatch('settings/changeSetting', {
          key: 'bankList',
          value: val
        })
      },
      get() {
        return this.$store.state.settings.bankList
      }
    }
  },

  created() {
    this.$nextTick(() => {
      onVisibilityChange('', true)
    })
    setTimeout(() => {
      this.getBankData()
    }, 500);
    window.loadBank=(type)=>{
      this.againLoadBank(type)
    }
  },
  mounted () {
    this.$H5Object.changeStatusBarBgColor('#F4F5FC')
    this.$H5Object.changeStatusIsDarkFont(true)
    
  },
  methods: {
    // 跳转借款协议
    gotoLoan(){
      this.$router.push(
        {
          path:'/loanAgreementUse',
          query:{
            type:'withdrawal',
          }
          
        }
      )
    },
    againLoadBank(){
      console.log('重新加载');
      this.$nextTick(() => {
        onVisibilityChange('', true)
      })
      this.getBankData()
    },
    // 获取信息
    async getBankData() {
      try {
        const { data } = await getBankData() || {}
        this.display_agreement=data.OZYGgAYz
        this.display_tips=data.JPinjoTA
        this.bankList = data?.pdsrgeelcii || []
        this.bankList.forEach(item => {
          item.bank_name = item.Full_name
          item.bank_code = item.bankCode
        })

        if (data?.Wallet.length) {
          const walletList = data?.Wallet
          walletList.forEach(item => {
            item.pay_channel = item.pai_channel
          })
          this.swipeList.push({ name: 'wallet', refid: '0', list: walletList || [] })
        }

        if (data?.Remittance_shop.length) {
          const remittanceList = data?.Remittance_shop
          remittanceList.forEach(item => {
            item.pay_channel = item.pai_channel
            item.channel_name = item.Full_name
          })
          remittanceList.forEach(item => {
            item.pay_channel = item.pai_channel
          })
          // this.swipeList.push({ name: 'remittance', refid: '1', list: remittanceList || [] })
        }

        if (this.bankList.length) {
          this.swipeList.push({ name: 'bank', refid: '2', channel_id: this.bankList[0].pai_channel || '' })
        }
        setTimeout(() => {
          let subRefs = ''
          if(this.swipeList.length>0){
            subRefs = 'withdrawal'+this.swipeList[0].refid
            this.subRefs = subRefs
          }
        }, 0);
      } catch (err) {
        console.log(err)
      }
    },

    //  返回操作
    headerBack() {
      this.$H5Object.cancleView()
    },

    async submitValidate() {
      console.log(1111113333,'提交');
      try {
        const dataForm = JSON.parse(JSON.stringify(this.$refs[this.subRefs][0].dataForm))
        dataForm.card_account = this.$refs[this.subRefs][0].dataForm.card_account.replace(/\s+/g, '')
        if (!dataForm.bank_code)dataForm.bank_code = ''

        if (this.subRefs !== 'withdrawal2') {
          dataForm.card_account = dataForm.card_account.replace(/^63/g, '0')
        }
        this.bankPoint.submit_num+=1
        const subParams = this.setSubData(dataForm)
        const { data } = await enchashmentWaySubmit(subParams) || {}
        await bankPoint(this.bankPoint)
        this.$H5Object.goIdentityVerPage()
        console.log(data)
      } catch (err) {
        console.log(err)
      }
    },

    setSubData(params) {
      const obj = {}
      Object.keys(params).forEach(item => {
        if (withdrawalSubmit[item]) {
          obj[withdrawalSubmit[item]] = params[item]
        }
      })
      return obj
    },

    onChange(index) {
      console.log(index);
      this.initialSwipe = index
      this.bankPoint.way_num+=1
      this.subRefs = 'withdrawal' + this.swipeList[index].refid
      this.submitFlag = this.$refs[this.subRefs][0].submitFlag
      this.confirmTitle = this.$t('baseInfo.withdrawalTips')[this.swipeList[index].refid * 1]
    },

    // 设置提交状态
    setSubmitFlag(validate) {
      console.log(validate,'validatevalidates的是非观');
      this.submitFlag = validate
    },

    // 展示弹窗
    showDialog() {
      // if(this.display_agreement){
      //   if(!this.isLoan){
      //     // this.$toast({
      //     //   message:this.$t('loan.lookloanxieyi')
      //     // })
      //     this.loanShow=true
      //     return
      //   }
      // }
      // console.log(this.swipeList,'111122',this.$refs[this.subRefs][0].dataForm);
      const dataForm = this.$refs[this.subRefs][0].dataForm
      this.bankPoint={...this.bankPoint,...this.$refs[this.subRefs][0].bankPoint}
      this.cardName = dataForm.channel_name
      this.cardAccount = dataForm.card_account
      if (!this.validateData()) return
      this.bankPoint.confirm_num +=1
      this.dialogVisible = true
      this.$nextTick(() => {
        this.$refs.baseDialog.init()
      })
    },
    validateData() {
      let flag = true
      const dataForm = JSON.parse(JSON.stringify(this.$refs[this.subRefs][0].dataForm))
      const test09 = /09\d{9}/ // 09开头11位
      const test9 = /^9\d{9}/ // 9开头10位
      const test63 = /^63\d{10}/
      const testName = /^[A-Za-z\s]+$/ //只能是字母和空格
      // 电子钱包校验
      if (this.subRefs === 'withdrawal0') {
        if (!dataForm.channel_id) {
          this.$toast.fail(this.$t('baseInfo.selectReceiving'))
          flag = false
        }
        if (!test09.test(dataForm.card_account) && !test9.test(dataForm.card_account) && !test63.test(dataForm.card_account) && flag) {
          this.$toast.fail(this.$t('rules.iphoneTest'))
          flag = false
        }
        if(!testName.test(dataForm.firstName ) || !testName.test(dataForm.lastName) ){
          this.$toast.fail(this.$t('baseInfo.errorname'))
          flag = false
        }
        if(!dataForm.firstName || !dataForm.lastName ){
          this.$toast.fail(this.$t('baseInfo.errorname'))
          flag = false
        }
      }
      // 汇款店校验
      if (this.subRefs === 'withdrawal1') {
        if (!dataForm.channel_id) {
          this.$toast.fail(this.$t('baseInfo.selectShop'))
          flag = false
        }
        if (!test09.test(dataForm.card_account) && !test9.test(dataForm.card_account) && flag) {
          this.$toast.fail(this.$t('rules.iphoneTest'))
          flag = false
        }
        if(!testName.test(dataForm.firstName ) || !testName.test(dataForm.lastName)  ){
          this.$toast.fail(this.$t('baseInfo.errorname'))
          flag = false
        }
        if(!dataForm.firstName || !dataForm.lastName ){
          this.$toast.fail(this.$t('baseInfo.errorname'))
          flag = false
        }
      }

      // 银行校验
      if (this.subRefs === 'withdrawal2') {
//         card_account
// confirm_account
        if (!dataForm.channel_name) {
          this.$toast.fail(this.$t('baseInfo.selectBank'))
          flag = false
        }
        if (dataForm.card_account !== dataForm.confirm_account && flag) {
          this.$toast.fail(this.$t('baseInfo.bankNumberIsdiff'))
          flag = false
        }
        if ((dataForm.card_account==''||dataForm.card_account.length==0) && flag) {
          this.$toast.fail(this.$t('baseInfo.bankNumberIsError'))
          flag = false
        }
        if(!testName.test(dataForm.firstName ) || !testName.test(dataForm.lastName)){
          this.$toast.fail(this.$t('baseInfo.errorname'))
          flag = false
        }
        if(!dataForm.firstName || !dataForm.lastName ){
          this.$toast.fail(this.$t('baseInfo.errorname'))
          flag = false
        }
      }
      return flag
    },

    // 信息确认提交
    confirm() {
      
      this.submitValidate()
    }
  }
}
</script>
<style lang="scss" scoped>
  .add-explain{
    padding: 24px;
    width: 670px;
    margin: 0 auto;
    background: #EBF3FF;
    border-radius: 16px;
    margin-bottom: 30px;
font-size: 24px;
font-family: Roboto-Regular, Roboto;
font-weight: 400;
color: #005FE9;
line-height: 36px;
  }
:deep(.is-content){
  padding: 0;
  padding-bottom: 40px;
}
:deep(.headerbase){
  background: rgba(244, 245, 252, 0);
}
.data-form{
  position: relative;
 
}
:deep(.van-form){
  background: #FFFFFF;
  border-radius: 48px 48px 0 0;
  padding-top: 80px;
  position: relative;
}
.module-title{
  font-size: 32px;
  font-weight: bold;
  color: #000000;
  line-height: 48px;
  margin-bottom: 24px;
}
:deep(.van-radio){
  display: initial;
}
:deep(.van-radio__icon){
  height: auto;
}

:deep(.has-val .van-field__control:disabled){
  -webkit-text-fill-color: initial;
}

:deep(.form-item){
  overflow: initial;
}

:deep(.uppercase input){
  text-transform: uppercase;
}

.step-line{
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  margin-bottom: 24px;
  padding: 0 40px;
  span{
    // padding: 0 28px;
    text-align: center;
    font-size: 24px;
    color: #005FE9;
    line-height: 36px;
    border-bottom: 6px solid #005FE9;
    padding-bottom: 16px;
    margin-right: 12px;
  }
}
.step-line span:nth-child(1){
  letter-spacing: -0.28px;
}
.step-line span:nth-child(2){
  margin-left: 12px;
}
.step-line span:nth-child(3){
  margin-left: 12px;
  margin-right:0;
  color: #B3B3B3;
  border-bottom: 6px solid #E6E6E6FF;
}
:deep(.btn-box){
  padding: 0 40px;
  margin-top: 50px;
}
:deep(.van-swipe__track){
  display: flex;
  align-items: center;
}

:deep(.van-swipe-item){
  position: relative;
  background-size: 100%!important;
}
:deep(.van-swipe__indicators){
  display: none;
}

:deep(.van-dialog){
  width: auto;
  left: 40px;
  right: 40px;
  transform: translate3d(0%,-50%,0);
  .van-dialog__content{
    padding: 48px 64px 50px 64px;
    line-height: 40px;
    text-align: left;
  }
  .van-dialog__footer{
    display: block;
    padding-bottom: 100px;
    .van-dialog__cancel{
      bottom: 0px;
      position: absolute;
      font-size: 36px;
      font-weight: 500;
      color: #1C1D21;
    }
    .van-dialog__confirm{
      height: 128px;
      padding-left: 64px;
      padding-right: 64px;
      color: #fff;
      font-size: 36px;
      font-weight: 500;
      .van-button__content{
        height: 128px;
        background: #1C1D21;
        border-radius: 32px;
      }
    }
  }
}
:deep([class*=van-hairline]::after){
  display: none;
}

.confirm-title{
  font-size: 32px;
  color: #292A4D;
  text-align: center;
}
.confirm-cont{
  font-size: 48px;
  font-weight: bold;
  color: #000000;
  text-align: center;
  line-height: 50px;
}
.confirm-bank{
  .item{
    font-size: 28px;
    color: #000000;
    margin-top: 32px;
    text-align: center;
    margin-bottom: 16px;
  }
}
.only-number{
  margin-top: 48px;
}
:deep(.child-title){
  font-size: 40px;
}
.swipe-box{
  position: relative;
  &::before{
    content:'';
    position: absolute;
    width: 46px;
    top: 0;
    bottom: 0;
    left: 12px;
    background: url('~@/assets/images/baseInfo/left-arrow-icon.png') no-repeat center center;
    background-size: 100%;
  }
  &::after{
    content:'';
    position: absolute;
    width: 46px;
    top: 0;
    bottom: 0;
    right:12px;
    background: url('~@/assets/images/baseInfo/right-arrow.png') no-repeat center center;
    background-size: 100%;
  }
}
.nabasa{
  display: flex;
  align-items: center;
font-size: 26px;
font-family: Roboto-Regular, Roboto;
font-weight: 400;
color: #999999;
line-height: 40px;
margin: 32px 0;
padding: 0 40px;
img{
  width: 36px;
  height: 36px;
  margin-right: 16px;
}
span:last-child{
  color: rgba(0, 95, 233, 1);
}
}
.popup-class{
  padding: 32px;
  .title{
    font-size: 48px;
    font-family: Roboto-Bold, Roboto;
    font-weight: bold;
    color: #000000;
    line-height: 72px;
    text-align: center;
  }
  .popup-content{
    margin-top: 32px;
    width: 606px;
    height: 602px;
    overflow-y: scroll;
    background: #F7F7F7;
    border-radius: 24px;
    padding: 32px;
    
    /deep/ .content{
      background: none;
    }
  }
  .footer-btn{
    
    width: 542px;
    background: #1C1D21;
    border-radius: 16px;
    margin: 0 auto;
    padding: 20px 46px;
    margin-top: 46px;
    font-size: 32px;
    font-family: Roboto-Regular, Roboto;
    font-weight: 400;
    color: #FFFFFF;
    text-align: center;
    line-height: 48px;
  }
}
</style>
