<template>
  <div class="electronic-wallet">
    <div class="title">{{ $t('baseInfo.bankCard') }}</div>
    <div>
      <div class="item-title">{{ $t('baseInfo.selectBank') }}</div>
      <van-field v-model="dataForm.channel_name" :class="{'has-val':String(dataForm.channel_name).length}" readonly class="form-item is-select" :placeholder="$t('baseInfo.bankName')" @click="gobankList()" />
      <div class="item-title">{{ $t('baseInfo.bankNumber') }}</div>

      <div class="form-item">
        <input  type="tel" class="input-box " v-model="dataForm.card_account" :placeholder="$t('baseInfo.inputBankNumber')" 
        @focus="focusFun('enter_card_num')" @blur="blurFun('enter_card_duration')"
        />
      </div>
      <!-- <van-field v-model="dataForm.card_account" maxlength="19" type="tel" class="form-item" :placeholder="$t('baseInfo.inputBankNumber')" /> -->
      <div class="item-title">{{ $t('baseInfo.confirmBankNumber') }}</div>
      <div class="form-item">
        <input  @focus="focusFun('confirm_enter_card_num')"  type="tel" class="input-box " v-model="dataForm.confirm_account" :placeholder="$t('baseInfo.confirmBankNumber')" 
       
        />
      </div>
      <!-- <van-field v-model="dataForm.confirm_account" maxlength="19" type="tel" class="form-item" :placeholder="$t('baseInfo.confirmBankNumber')" /> -->
      <div class="name-con">
        <div class="name-title">
          <span>{{$t('baseInfo.fillpayeename')}}</span>
          <span>{{$t('baseInfo.pleasepayeeName')}}</span>
        </div>
        <div class="name-form">
          <div class="short-con">  
            <div class="name short-name">
              <span>{{$t('baseInfo.lastname')}}</span>
              <textarea cols="30" min-rows="1" v-model.trim="dataForm.lastName" @focus="focusFun('last_name_num')" @blur="blurFun('last_name_duration')"></textarea>
            </div>
            <div class="name short-name">
              <span>{{$t('baseInfo.firstname')}}</span>
              <textarea cols="30" min-rows="1" v-model.trim="dataForm.firstName" @focus="focusFun('first_name_num')" @blur="blurFun('first_name_duration')"></textarea>
            </div>
          </div>
          
          <div class="name long-name">
            <span>{{$t('baseInfo.middlename')}}</span>
            <textarea cols="30" min-rows="1" v-model.trim="dataForm.middleName" @focus="focusFun('middle_name_num')" @blur="blurFun('middle_name_duration')"></textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getnewtime, getinterval } from '@/utils'
export default {
  props: {
    channelId: {
      type: [Number, String],
      default: ''
    }
  },
  data() {
    return {
      submitFlag: false,
      dataForm: {
        payout_type: 1,
        channel_id: '',
        channel_name: '',
        card_account: '',
        confirm_account: '',
        bank_code: '',
        lastName:'',
        firstName:'',
        middleName:'',
      },
      bankPoint:{
        // account_duration:0,//	账号输入时长
        // account_updatecount:0,//	账号修改次数
        // account_num:1,//	账号点击次数
        last_name_duration:0,//	last_name输入时长
        last_name_updatecount:0,//	last_name修改次数
        last_name_num:1,//	last_name点击次数
        first_name_duration:0,//	first_name输入时长
        first_name_updatecount:0,//	first_name修改次数
        first_name_num:1,//	first_name点击次数
        middle_name_duration:0,//	middle_name输入时长
        middle_name_updatecount:0,//	middle_name修改次数
        middle_name_num:1,//	middle_name点击次数
        bank_num:0, // 银行卡 选择次数
        enter_card_duration:0, // 输入卡号 修改时长 
        enter_card_num:1, // 输入卡号 点击次数
        enter_card_updatecount:0, // 输入卡号 修改次数
        confirm_enter_card_num:0, // 确认输入卡号 点击次数
      }
    }
  },
  computed: {
    bankList: {
      get() {
        return this.$store.state.settings.bankList
      }
    },
    checkBank: {
      get() {
        return this.$store.state.settings.checkBank
      }
    }
  },
  watch: {
    'dataForm.card_account'(val) {
      this.$nextTick(() => {
        this.dataForm.card_account = val.replace(/\D/g, '').replace(/....(?!$)/g, '$& ')
      })
      this.updatecountfun('card_account','enter_card_updatecount')
    },
    'dataForm.lastName':{
      handler(){
        this.updatecountfun('lastName','last_name_updatecount')
      }
    },
    'dataForm.firstName':{
      handler(){
        this.updatecountfun('firstName','first_name_updatecount')
      }
    },
    'dataForm.middleName':{
      handler(){
        this.updatecountfun('middleName','middle_name_updatecount')
      }
    },
    'dataForm.confirm_account'(val) {
      this.$nextTick(() => {
        this.dataForm.confirm_account = val.replace(/\D/g, '').replace(/....(?!$)/g, '$& ')
      })
    },
    'dataForm.channel_name'(val){
      console.log(val,4444);
    },
    submitFlag: {
      handler(val) {
        this.$emit('setSubmitFlag', val)
      }
    },
    dataForm: {
      handler() {
        let flag = true
        Object.keys(this.dataForm).forEach(item => {
          if (!this.dataForm[item])flag = false
        })
        this.submitFlag = flag
      },
      deep: true
    },
    checkBank: {
      handler(val) {
        if (val) {
          const item = this.bankList.find(item => { return item.bank_name === val })
          this.dataForm.channel_name = item.bank_name
          this.dataForm.bank_code = item.bank_code
        }
      },
      immediate: true
    },
    channelId: {
      handler() {
        this.dataForm.channel_id = this.channelId
      },
      immediate: true
    }
  },
  methods: {
    // 获取焦点
    focusFun(nametwo) {
      this.start = getnewtime(); // 初始化时间
      this.bankPoint[nametwo]++
    },

    // 失去焦点 - 计算时长
    blurFun(name) { 
      //  计算时长
      this.bankPoint[name] += getinterval(
        getnewtime(),
        this.start
      );
    },
    // 次数
    updatecountfun(name,nametwo) {
      // 初始化不要走
      if (!this.dataForm[name]) {
        this.bankPoint[nametwo]++;
      }
    },
    gobankList() {
      this.bankPoint.bank_num+=1
      console.log(this.bankPoint.bank_num)
      this.$router.push({ name: 'BankList' })
    }
  }
}
</script>
<style lang="scss" scoped>
.electronic-wallet{
  padding: 48px;
  width: 606px;
  margin: 0 auto;
  background: url('~@/assets/images/baseInfo/bank-card-bj-icon.png') no-repeat center center;
  background-size: 100% 100%;
  .title{
    font-size: 32px;
    font-family: Roboto-Bold, Roboto;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 48px;
    margin: 0 auto;
    display: table;
    position: relative;
    margin-bottom: 48px;
    &::before,&::after{
      content:'';
      position: absolute;
      width: 12px;
      height: 12px;
      background: #FFFFFF;
      border-radius: 50%;
      left: -20px;
      top: 50%;
      margin-top: -6px;
    }
    &::after{
      left: initial;
      right: -20px;
    }
  }
  .item-title{
    font-size: 26px;
    color: #FFFFFF;
    line-height: 40px;
    margin-bottom: 16px;
  }

  .form-item{
    background: #FFFFFF!important;
    margin-bottom: 20px!important;
  }
  .form-item{
    font-size: 24px;
    height: 100px;
    background: #FFFFFF;
    border-radius: 16px;
    overflow: hidden;
    .input-box{
      padding: 0 30px;
      width: 100%;
      height: 100%;
      
      color: #0B0320;
    }
  }
}
.name-con{
    // 名字区域
    .name-form{
      margin-bottom: 24px;
      .short-con{
        display: flex;
        margin-bottom: 14px;
      }
      .name{
        background: #FFFFFF;
        border-radius: 16px;
        padding: 16px;
        margin-right: 14px;
        flex: 1;
        display: flex;
        flex-direction: column;
        textarea{
          height: 30px;
          width: 100%;
          line-height: 30px;
        }
        span{
          font-size: 22px;
          font-family: Roboto-Regular, Roboto;
          font-weight: 400;
          color: #CCCCCC;
          line-height: 26px;
          margin-bottom: 12px;
        }
      }
    }
    .name-title{
      span{
        display: block;
        &:first-child{
          font-size: 26px;
          font-family: Roboto-Regular, Roboto;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 40px;
        }
        &:last-child{
          font-size: 24px;
          font-family: Roboto-Regular, Roboto;
          font-weight: 400;
          color: rgba(255,255,255,0.7);
          line-height: 32px;
          margin-bottom: 16px;
        }
      }
    }
    
  }
</style>
