<template>
  <div class="electronic-wallet">
    <div class="title">{{ $t('baseInfo.digitalWallet') }}</div>
    <div class="select-remark">{{ $t('baseInfo.selectReceiving') }} </div>
    <div>
      <van-radio-group v-model="dataForm.channel_id">
        <van-radio @click="walletClick()" v-for="(item, index) in walletList" :key="index" class="clearfix" :name="item.pay_channel">
          {{ item.channel_name }}
          <template #icon="props">
            <span class="img-icon" :class="{ 'is-checked': props.checked }" />
          </template>
        </van-radio>
      </van-radio-group>
      <div class="name-con">
        <div class="name-title">
          <span>{{$t('baseInfo.fillpayeename')}}</span>
          <span>{{$t('baseInfo.pleasepayeeName')}}</span>
        </div>
        <div class="name-form">
          <div class="short-con">  
            <div class="name short-name">
              <span>{{$t('baseInfo.lastname')}}</span>
              <textarea cols="30" min-rows="1" v-model.trim="dataForm.lastName" @focus="focusFun('last_name_num')" @blur="blurFun('last_name_duration')"></textarea>
            </div>
            <div class="name short-name">
              <span>{{$t('baseInfo.firstname')}}</span>
              <textarea cols="30" min-rows="1" v-model.trim="dataForm.firstName" @focus="focusFun('first_name_num')" @blur="blurFun('first_name_duration')"></textarea>
            </div>
          </div>
          <div class="name long-name">
            <span>{{$t('baseInfo.middlename')}}</span>
            <textarea cols="30" min-rows="1" v-model.trim="dataForm.middleName"
            @focus="focusFun('middle_name_num')" @blur="blurFun('middle_name_duration')"
            
            ></textarea>
          </div>
        </div>
      </div>

      <div class="form-item">
        <input :maxlength="maxlength" type="tel" class="input-box " v-model="dataForm.card_account" :placeholder="$t('baseInfo.addwallet')" 
        @focus="focusFun('account_num')" @blur="blurFun('account_duration')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { getnewtime, getinterval } from '@/utils'
export default {
  props: {
    walletList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      submitFlag: false,
      dataForm: {
        payout_type: 2,
        channel_id: '',
        channel_name: '',
        card_account: '',
        lastName:'',
        firstName:'',
        middleName:'',
      },
      bankPoint:{
        account_duration:0,//	账号输入时长
        account_updatecount:0,//	账号修改次数
        account_num:1,//	账号点击次数
        last_name_duration:0,//	last_name输入时长
        last_name_updatecount:0,//	last_name修改次数
        last_name_num:1,//	last_name点击次数
        first_name_duration:0,//	first_name输入时长
        first_name_updatecount:0,//	first_name修改次数
        first_name_num:1,//	first_name点击次数
        middle_name_duration:0,//	middle_name输入时长
        middle_name_updatecount:0,//	middle_name修改次数
        middle_name_num:1,//	middle_name点击次数
        select_payment_num:1, // 选择收款电子钱包
      }
    }
  },
  computed: {
    maxlength() {
      if (this.dataForm.card_account.indexOf('09') === 0) {
        return 11
      } else if (this.dataForm.card_account.indexOf('9') === 0) {
        return 10
      } else {
        return 12
      }
    }
  },
  watch: {
    'dataForm.channel_id': {
      handler(val) {
        const item = this.walletList.find(item => { return item.pay_channel + '' === val + '' })
        this.dataForm.channel_name = item.channel_name
      }
    },
    submitFlag: {
      handler(val) {
        this.$emit('setSubmitFlag', val)
      }
    },
    dataForm: {
      handler() {
        let flag = true
        Object.keys(this.dataForm).forEach(item => {
          if (!this.dataForm[item]) flag = false
        })
        this.submitFlag = flag
      },
      deep: true
    },
    'dataForm.card_account':{
      handler(){
        this.updatecountfun('card_account','account_updatecount')
      }
    },
    'dataForm.lastName':{
      handler(){
        this.updatecountfun('lastName','last_name_updatecount')
      }
    },
    'dataForm.firstName':{
      handler(){
        this.updatecountfun('firstName','first_name_updatecount')
      }
    },
    'dataForm.middleName':{
      handler(){
        this.updatecountfun('middleName','middle_name_updatecount')
      }
    },
  },
  mounted () {
    this.initTextarea()
  },
  methods: {

    // 获取焦点
    focusFun(nametwo) {
      this.start = getnewtime(); // 初始化时间
      this.bankPoint[nametwo]++
    },

    // 失去焦点 - 计算时长
    blurFun(name) { 
      //  计算时长
      this.bankPoint[name] += getinterval(
        getnewtime(),
        this.start
      );
    },
    // 次数
    updatecountfun(name,nametwo) {
      // 初始化不要走
      if (!this.dataForm[name]) {
        this.bankPoint[nametwo]++;
      }
    },


    autoTextarea(e) {
      let elem = e.target
      let rootFontSize = parseFloat(document.documentElement.style.fontSize)
      elem.style.height = 'auto'
      elem.scrollTop = 0 // 防抖动
      elem.style.height = elem.scrollHeight / rootFontSize + 'rem'
    },
    initTextarea() {
      let textarea = document.querySelectorAll('textarea')
      for (let i in textarea) {
        // 頁面一共9个textarea，第10个是entries()  方法会导致add报错，判断一下
        if (typeof textarea[i] != 'object') return
        textarea[i].addEventListener('input', (e) => {
          this.autoTextarea(e)
          // textarea[i].style.height = e.target.scrollHeight + 'px'
          // textarea[i].value = e.target.value.replace(/\s+/g, '')
          textarea[i].value = e.target.value.replace(/[^\s+a-zA-Z]/g, '')
        })
      }
    },

    walletClick(){
      this.bankPoint.select_payment_num+=1
    }
  }

}
</script>
<style lang="scss" scoped>
.electronic-wallet{
  padding: 48px;
  width: 606px;
  margin: 0 auto;
  background: url('~@/assets/images/baseInfo/waller-bj-icon.png') no-repeat center center;
  background-size: 100% 100%;
  .title{
    font-size: 32px;
    font-family: Roboto-Bold, Roboto;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 48px;
    margin: 0 auto;
    display: table;
    position: relative;
    margin-bottom: 48px;
    &::before,&::after{
      content:'';
      position: absolute;
      width: 12px;
      height: 12px;
      background: #FFFFFF;
      border-radius: 50%;
      left: -20px;
      top: 50%;
      margin-top: -6px;
    }
    &::after{
      left: initial;
      right: -20px;
    }
  }
  .select-remark{
    font-size: 26px;
    font-family: Roboto-Regular, Roboto;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 40px;
    margin-bottom: 16px;
  }
  .img-icon{
    display: block;
    width: 36px;
    height: 36px;
    background: url('~@/assets/images/baseInfo/whit-check.png') no-repeat center center;
    background-size: 100%!important;
  }
  .img-icon.is-checked{
    background: url('~@/assets/images/baseInfo/whit-checked.png') no-repeat center center;
  }
  :deep(.van-radio){
    display: block;
    overflow: auto;
    height: 72px;
    line-height: 72px;
    margin-bottom: 24px;
    .van-radio__icon{
      float: right;
      display: flex;
      align-items: center;
      height: 100%;
    }
  }
  :deep(.van-radio__label){
    font-size: 48px;
    font-weight: bold;
    color: #FFFFFF;
    float: left;
    line-height: 72px;
  }
  :deep(.van-radio-group){
    margin-bottom: 24px;
  }
  .form-item{
    font-size: 24px;
    height: 100px;
    background: #FFFFFF;
    border-radius: 16px;
    overflow: hidden;
    .input-box{
      padding: 0 30px;
      width: 100%;
      height: 100%;
      
      color: #0B0320;
    }
  }
  .name-con{
    // 名字区域
    .name-form{
      margin-bottom: 48px;
      .short-con{
        display: flex;
        margin-bottom: 14px;
      }
      .name{
        background: #FFFFFF;
        border-radius: 16px;
        padding: 16px;
        margin-right: 14px;
        flex: 1;
        display: flex;
        flex-direction: column;
        textarea{
          height: 30px;
          width: 100%;
          line-height: 30px;
        }
        span{
          font-size: 22px;
          font-family: Roboto-Regular, Roboto;
          font-weight: 400;
          color: #CCCCCC;
          line-height: 26px;
          margin-bottom: 12px;
        }
      }
    }
    .name-title{
      span{
        display: block;
        &:first-child{
          font-size: 26px;
          font-family: Roboto-Regular, Roboto;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 40px;
        }
        &:last-child{
          font-size: 24px;
          font-family: Roboto-Regular, Roboto;
          font-weight: 400;
          color: rgba(255,255,255,0.7);
          line-height: 32px;
          margin-bottom: 16px;
        }
      }
    }
    
  }
}
</style>
